import React from 'react';
import { graphql } from 'gatsby';
import Catalog from '../components/Catalog'
// import gql from "graphql-tag";
import {useLazyQuery, gql} from "@apollo/client";
import {catalogFragments} from "../fragments/live/catalog";
import "../fragments/static/catalog";

const liveMode = false;

export default function CatalogPage(props) {
    const { data, pageContext } = props;

    const [categoryList, {called, error, loading, data: liveData}] = useLazyQuery(
        liveQuery,
        {
            variables: {
                categoryId: pageContext.categoryId,
            },
            fetchPolicy: 'network-only',
        }
    );

    if (!liveMode) {
        // Use gatsby build-time content
        return <Catalog category={data.magento.categoryList[0]}
                        categories={pageContext.categories}
                        filterAttributes={pageContext.filterMeta.items}
                        location={props.location} />;
    }

    if (!called) {
        categoryList()
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (called && liveData) {
        return <Catalog category={liveData.categoryList[0]}
                categories={pageContext.categories}
                filterAttributes={pageContext.filterMeta.items}
                location={props.location} />;
    }

    return null;
}

export const liveQuery = gql`
    query categoryList ($categoryId: String) {
        categoryList(filters: { ids: { eq: $categoryId } }) {
            id
            ...categoryContent
            children {
                id
                ...categoryContent
            }
        }
    }

    ${catalogFragments}
`;

export const query = graphql`
    query($categoryId: String) {
        magento {
            categoryList(filters: { ids: { eq: $categoryId } }) {
                ...categoryContent
                children {
                    ...categoryContent
                }
            }
        }
    }
`;
