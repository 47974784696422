import { gql } from "@apollo/client";

export const catalogFragments = gql`
    fragment categoryContent on CategoryInterface {
        __typename
        name
        url_key
        level
        #    id
        ont_category_type
        is_anchor
        position
        description
        ont_image_url_1
        breadcrumbs {
            category_level
            category_name
            category_url_key
        }
        products(pageSize: 100) {
            __typename
            items {
                id
                categories {
                    id
                }
                sku
                name
                short_description {
                    html
                }
                description {
                    html
                }
                url_key
                ont_image_url_1
                ont_prep_display
                ont_throughput
                ont_read_len_display
                ont_concentration
                ont_badges
                ont_approach
                ont_sample_type
                ont_multiplexing
                price_range {
                    minimum_price {
                        final_price {
                            currency
                            value
                        }
                    }
                }
            }
        }
    }
`;

export const catalogNavigationFragments = gql`
    fragment navigationCategoryData on CategoryInterface {
        id
        name
        url_key
        is_anchor
        position
        level
    }

    fragment navigationFilterAttributeData on Attribute {
        attribute_code
        attribute_type
        attribute_options {
            label
            value
        }
    }

    fragment navigationProductData on ProductInterface {
        id
        name
        url_key
    }
`;
